import { FaCcVisa } from "react-icons/fa";
import { FaCcMastercard } from "react-icons/fa";
import { FaCcApplePay } from "react-icons/fa";
import { FaGooglePay } from "react-icons/fa";
import s from "./Footer.module.css";

const Footer = () => {
  return (
    <div className={s.footer}>
      <div className={s.container}>
        <div className={s.footerInner}>
          <div className={s.footerBlock}>
            <div className={s.footerContent}>
              <div className={s.footerLogo}>
                <svg
                  width="191"
                  height="55"
                  viewBox="0 0 191 55"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M0.783889 22.7154C0.573006 22.7154 0.387685 22.6366 0.230055 22.4768C0.0766848 22.3192 0 22.1339 0 21.923V1.46516C0 1.23298 0.0702943 1.03701 0.213013 0.877247C0.359993 0.719617 0.549574 0.638672 0.783889 0.638672H9.71341C12.4442 0.638672 14.5872 1.26067 16.1379 2.50467C17.6929 3.74441 18.4725 5.56141 18.4725 7.95781C18.4725 10.3563 17.6929 12.1478 16.1379 13.3343C14.5872 14.5229 12.4442 15.1151 9.71341 15.1151H6.11775V21.923C6.11775 22.1339 6.04319 22.3192 5.89621 22.4768C5.7471 22.6366 5.55752 22.7154 5.32534 22.7154H0.783889ZM9.55152 10.6077C10.4142 10.6077 11.0937 10.384 11.5879 9.93458C12.0821 9.48086 12.3292 8.80987 12.3292 7.92373C12.3292 7.10576 12.1013 6.44542 11.6476 5.93845C11.1981 5.4336 10.4994 5.18012 9.55152 5.18012H6.02402V10.6077H9.55152Z"
                    fill="white"
                  />
                  <path
                    d="M20.1756 22.7154C20.0095 22.7154 19.8604 22.6472 19.724 22.5109C19.5877 22.3746 19.5195 22.2212 19.5195 22.0508C19.5195 21.9272 19.5281 21.8335 19.5451 21.7696L26.8983 1.55889C27.1071 0.945411 27.5288 0.638672 28.1594 0.638672H33.0757C33.7062 0.638672 34.1259 0.945411 34.3367 1.55889L41.6815 21.7696C41.7028 21.8335 41.7155 21.9272 41.7155 22.0508C41.7155 22.2212 41.6474 22.3746 41.511 22.5109C41.3747 22.6472 41.2213 22.7154 41.0509 22.7154H36.9526C36.4456 22.7154 36.0877 22.4853 35.879 22.0252L34.7457 18.9663H26.4893L25.3476 22.0252C25.1367 22.4853 24.7788 22.7154 24.274 22.7154H20.1756ZM27.81 14.2289H33.425L30.6133 6.19406L27.81 14.2289Z"
                    fill="white"
                  />
                  <path
                    d="M50.5709 22.7154C50.36 22.7154 50.1747 22.6366 50.017 22.4768C49.8637 22.3192 49.787 22.1339 49.787 21.923V15.2088L42.5019 1.65262C42.438 1.54611 42.4082 1.42895 42.4082 1.30327C42.4082 1.13286 42.4764 0.979493 42.6127 0.843165C42.749 0.706836 42.9024 0.638672 43.0728 0.638672H47.2308C47.5248 0.638672 47.7676 0.713227 47.9551 0.860206C48.1425 1.00932 48.2789 1.16695 48.3641 1.33736L52.7521 9.25294L57.1317 1.33736C57.2169 1.16695 57.3532 1.00932 57.5407 0.860206C57.7324 0.713227 57.9752 0.638672 58.2649 0.638672H62.4315C62.6019 0.638672 62.7552 0.706836 62.8916 0.843165C63.0279 0.979493 63.0961 1.13286 63.0961 1.30327C63.0961 1.42895 63.0641 1.54611 63.0023 1.65262L55.7173 15.2088V21.923C55.7173 22.1573 55.6363 22.3469 55.4787 22.4939C55.3189 22.643 55.123 22.7154 54.8908 22.7154H50.5709Z"
                    fill="white"
                  />
                  <path
                    d="M74.8217 23.0275C71.8161 23.0275 69.4559 22.3033 67.7411 20.8548C66.0242 19.4021 65.1168 17.2762 65.0146 14.4814C64.9911 13.9148 64.9805 12.9882 64.9805 11.7038C64.9805 10.4044 64.9911 9.45859 65.0146 8.86641C65.1168 6.13558 66.0328 4.02675 67.7667 2.54417C69.5049 1.0616 71.8565 0.320312 74.8217 0.320312C76.6898 0.320312 78.3662 0.627052 79.8488 1.24053C81.3314 1.84975 82.503 2.73162 83.3678 3.89042C84.2305 5.04495 84.6821 6.41037 84.7226 7.9888C84.7226 8.17625 84.6544 8.33388 84.5181 8.45743C84.3817 8.5831 84.2198 8.64488 84.0324 8.64488H79.5506C79.2545 8.64488 79.0394 8.58949 78.903 8.47447C78.7667 8.36157 78.6474 8.14643 78.5452 7.82691C78.2491 6.80018 77.7996 6.0802 77.1989 5.67121C76.6025 5.25796 75.8101 5.04921 74.8217 5.04921C72.4466 5.04921 71.2175 6.37629 71.1323 9.0283C71.1089 9.59705 71.0982 10.4683 71.0982 11.6441C71.0982 12.8199 71.1089 13.7146 71.1323 14.3281C71.2175 16.9758 72.4466 18.2986 74.8217 18.2986C75.7866 18.2986 76.5791 18.0899 77.1989 17.6681C77.823 17.2485 78.2725 16.5328 78.5452 15.521C78.624 15.21 78.7369 14.9969 78.886 14.8819C79.033 14.7626 79.2545 14.703 79.5506 14.703H84.0324C84.2198 14.703 84.3817 14.7669 84.5181 14.8904C84.6544 15.0161 84.7226 15.1759 84.7226 15.3676C84.6821 16.9418 84.2305 18.3072 83.3678 19.466C82.503 20.6205 81.3314 21.5024 79.8488 22.1158C78.3662 22.7229 76.6898 23.0275 74.8217 23.0275Z"
                    fill="white"
                  />
                  <path
                    d="M88.7253 22.7154C88.5144 22.7154 88.3291 22.6366 88.1715 22.4768C88.0181 22.3192 87.9414 22.1339 87.9414 21.923V1.46516C87.9414 1.23298 88.0117 1.03701 88.1544 0.877247C88.3014 0.719617 88.491 0.638672 88.7253 0.638672H103.611C103.843 0.638672 104.037 0.719617 104.19 0.877247C104.348 1.03701 104.429 1.23298 104.429 1.46516V4.58368C104.429 4.818 104.348 5.00758 104.19 5.15456C104.037 5.29728 103.843 5.36757 103.611 5.36757H93.6502V9.37222H102.921C103.146 9.37222 103.34 9.45317 103.5 9.6108C103.658 9.77056 103.738 9.96653 103.738 10.1987V13.0616C103.738 13.2959 103.658 13.4919 103.5 13.6495C103.34 13.8093 103.146 13.8881 102.921 13.8881H93.6502V17.9865H103.866C104.092 17.9865 104.286 18.0674 104.446 18.2251C104.603 18.3784 104.684 18.5723 104.684 18.8045V21.923C104.684 22.1573 104.603 22.3469 104.446 22.4939C104.286 22.643 104.092 22.7154 103.866 22.7154H88.7253Z"
                    fill="white"
                  />
                  <path
                    d="M108.962 22.7154C108.751 22.7154 108.565 22.6366 108.408 22.4768C108.254 22.3192 108.178 22.1339 108.178 21.923V1.46516C108.178 1.23298 108.248 1.03701 108.391 0.877247C108.538 0.719617 108.727 0.638672 108.962 0.638672H112.523C113.011 0.638672 113.369 0.841035 113.597 1.24363L121.044 13.002V1.46516C121.044 1.23298 121.114 1.03701 121.257 0.877247C121.404 0.719617 121.593 0.638672 121.828 0.638672H125.832C126.064 0.638672 126.254 0.719617 126.403 0.877247C126.55 1.03701 126.625 1.23298 126.625 1.46516V21.8974C126.625 22.1253 126.55 22.3192 126.403 22.4768C126.254 22.6366 126.064 22.7154 125.832 22.7154H122.271C121.787 22.7154 121.429 22.5173 121.197 22.1189L113.784 10.8292V21.923C113.784 22.1573 113.703 22.3469 113.546 22.4939C113.392 22.643 113.198 22.7154 112.966 22.7154H108.962Z"
                    fill="white"
                  />
                  <path
                    d="M136.586 22.7154C136.352 22.7154 136.156 22.643 135.998 22.4939C135.845 22.3469 135.768 22.1573 135.768 21.923V5.78508H130.213C129.985 5.78508 129.791 5.71265 129.633 5.56354C129.473 5.41656 129.395 5.22698 129.395 4.99267V1.46516C129.395 1.23298 129.473 1.03701 129.633 0.877247C129.791 0.719617 129.985 0.638672 130.213 0.638672H147.339C147.571 0.638672 147.765 0.719617 147.918 0.877247C148.076 1.03701 148.157 1.23298 148.157 1.46516V4.99267C148.157 5.22698 148.076 5.41656 147.918 5.56354C147.765 5.71265 147.571 5.78508 147.339 5.78508H141.792V21.923C141.792 22.1573 141.711 22.3469 141.553 22.4939C141.394 22.643 141.198 22.7154 140.965 22.7154H136.586Z"
                    fill="white"
                  />
                  <path
                    d="M151.719 22.7154C151.509 22.7154 151.323 22.6366 151.166 22.4768C151.012 22.3192 150.936 22.1339 150.936 21.923V1.46516C150.936 1.23298 151.006 1.03701 151.149 0.877247C151.296 0.719617 151.485 0.638672 151.719 0.638672H166.605C166.837 0.638672 167.031 0.719617 167.184 0.877247C167.342 1.03701 167.423 1.23298 167.423 1.46516V4.58368C167.423 4.818 167.342 5.00758 167.184 5.15456C167.031 5.29728 166.837 5.36757 166.605 5.36757H156.644V9.37222H165.915C166.14 9.37222 166.334 9.45317 166.494 9.6108C166.652 9.77056 166.733 9.96653 166.733 10.1987V13.0616C166.733 13.2959 166.652 13.4919 166.494 13.6495C166.334 13.8093 166.14 13.8881 165.915 13.8881H156.644V17.9865H166.86C167.086 17.9865 167.28 18.0674 167.44 18.2251C167.597 18.3784 167.678 18.5723 167.678 18.8045V21.923C167.678 22.1573 167.597 22.3469 167.44 22.4939C167.28 22.643 167.086 22.7154 166.86 22.7154H151.719Z"
                    fill="white"
                  />
                  <path
                    d="M171.964 22.7154C171.753 22.7154 171.567 22.6366 171.41 22.4768C171.256 22.3192 171.18 22.1339 171.18 21.923V1.46516C171.18 1.23298 171.25 1.03701 171.393 0.877247C171.54 0.719617 171.729 0.638672 171.964 0.638672H180.637C183.415 0.638672 185.584 1.26919 187.147 2.53023C188.715 3.79127 189.499 5.56993 189.499 7.86409C189.499 9.33601 189.154 10.5885 188.468 11.6216C187.786 12.6505 186.845 13.4493 185.648 14.0159L189.908 21.71C189.97 21.8356 190.002 21.9485 190.002 22.0508C190.002 22.2212 189.938 22.3746 189.814 22.5109C189.688 22.6472 189.529 22.7154 189.337 22.7154H184.898C184.284 22.7154 183.852 22.4321 183.603 21.8633L180.135 15.0213H177.101V21.923C177.101 22.1573 177.021 22.3469 176.863 22.4939C176.71 22.643 176.516 22.7154 176.283 22.7154H171.964ZM180.603 10.3862C181.507 10.3862 182.192 10.1625 182.665 9.71304C183.143 9.25933 183.381 8.63094 183.381 7.83001C183.381 7.02908 183.143 6.38791 182.665 5.90436C182.192 5.42295 181.507 5.18012 180.603 5.18012H177.101V10.3862H180.603Z"
                    fill="white"
                  />
                  <path
                    d="M27.0163 54.2056C26.8054 54.2056 26.6201 54.1268 26.4625 53.967C26.3091 53.8094 26.2324 53.6241 26.2324 53.4132V32.9554C26.2324 32.7232 26.3027 32.5272 26.4454 32.3675C26.5924 32.2099 26.782 32.1289 27.0163 32.1289H41.6802C41.9123 32.1289 42.1062 32.2099 42.2596 32.3675C42.4172 32.5272 42.4981 32.7232 42.4981 32.9554V36.2954C42.4981 36.5298 42.4172 36.7193 42.2596 36.8663C42.1062 37.009 41.9123 37.0793 41.6802 37.0793H32.0349V41.4674H41.0496C41.2818 41.4674 41.4757 41.5484 41.629 41.706C41.7867 41.8594 41.8676 42.0532 41.8676 42.2854V45.6254C41.8676 45.8598 41.7867 46.0493 41.629 46.1963C41.4757 46.3454 41.2818 46.4178 41.0496 46.4178H32.0349V53.4132C32.0349 53.6241 31.9604 53.8094 31.8134 53.967C31.6643 54.1268 31.4747 54.2056 31.2425 54.2056H27.0163Z"
                    fill="white"
                  />
                  <path
                    d="M46.5983 54.2076C46.3875 54.2076 46.2021 54.1288 46.0445 53.969C45.8911 53.8114 45.8145 53.626 45.8145 53.4152V32.9233C45.8145 32.6911 45.8911 32.5015 46.0445 32.3524C46.2021 32.2054 46.3875 32.1309 46.5983 32.1309H51.1057C51.3379 32.1309 51.5275 32.2054 51.6766 32.3524C51.8236 32.5015 51.8981 32.6911 51.8981 32.9233V53.4152C51.8981 53.626 51.8236 53.8114 51.6766 53.969C51.5275 54.1288 51.3379 54.2076 51.1057 54.2076H46.5983Z"
                    fill="white"
                  />
                  <path
                    d="M56.9694 54.2076C56.7586 54.2076 56.5732 54.1288 56.4156 53.969C56.2622 53.8114 56.1855 53.626 56.1855 53.4152V32.9574C56.1855 32.7252 56.2558 32.5292 56.3986 32.3694C56.5455 32.2118 56.7351 32.1309 56.9694 32.1309H60.531C61.0188 32.1309 61.3767 32.3332 61.6046 32.7358L69.0516 44.4942V32.9574C69.0516 32.7252 69.1219 32.5292 69.2646 32.3694C69.4116 32.2118 69.6011 32.1309 69.8354 32.1309H73.8401C74.0723 32.1309 74.2619 32.2118 74.411 32.3694C74.558 32.5292 74.6325 32.7252 74.6325 32.9574V53.3896C74.6325 53.6175 74.558 53.8114 74.411 53.969C74.2619 54.1288 74.0723 54.2076 73.8401 54.2076H70.2785C69.795 54.2076 69.4371 54.0095 69.2049 53.6111L61.7921 42.3214V53.4152C61.7921 53.6495 61.7111 53.8391 61.5535 53.986C61.4001 54.1351 61.2063 54.2076 60.9741 54.2076H56.9694Z"
                    fill="white"
                  />
                  <path
                    d="M77.6834 54.2076C77.5173 54.2076 77.3682 54.1394 77.2318 54.0031C77.0955 53.8668 77.0273 53.7134 77.0273 53.543C77.0273 53.4194 77.0359 53.3257 77.0529 53.2618L84.4061 33.0511C84.6149 32.4376 85.0366 32.1309 85.6672 32.1309H90.5835C91.214 32.1309 91.6337 32.4376 91.8446 33.0511L99.1893 53.2618C99.2106 53.3257 99.2233 53.4194 99.2233 53.543C99.2233 53.7134 99.1552 53.8668 99.0189 54.0031C98.8825 54.1394 98.7292 54.2076 98.5587 54.2076H94.4604C93.9534 54.2076 93.5955 53.9775 93.3868 53.5174L92.2535 50.4585H83.9971L82.8554 53.5174C82.6445 53.9775 82.2866 54.2076 81.7818 54.2076H77.6834ZM85.3178 45.7211H90.9329L88.1211 37.6863L85.3178 45.7211Z"
                    fill="white"
                  />
                  <path
                    d="M102.407 54.2076C102.196 54.2076 102.011 54.1288 101.853 53.969C101.7 53.8114 101.623 53.626 101.623 53.4152V32.9574C101.623 32.7252 101.693 32.5292 101.836 32.3694C101.983 32.2118 102.173 32.1309 102.407 32.1309H105.969C106.456 32.1309 106.814 32.3332 107.042 32.7358L114.489 44.4942V32.9574C114.489 32.7252 114.559 32.5292 114.702 32.3694C114.849 32.2118 115.039 32.1309 115.273 32.1309H119.278C119.51 32.1309 119.699 32.2118 119.848 32.3694C119.995 32.5292 120.07 32.7252 120.07 32.9574V53.3896C120.07 53.6175 119.995 53.8114 119.848 53.969C119.699 54.1288 119.51 54.2076 119.278 54.2076H115.716C115.232 54.2076 114.875 54.0095 114.642 53.6111L107.23 42.3214V53.4152C107.23 53.6495 107.149 53.8391 106.991 53.986C106.838 54.1351 106.644 54.2076 106.412 54.2076H102.407Z"
                    fill="white"
                  />
                  <path
                    d="M133.499 54.5178C130.494 54.5178 128.134 53.7935 126.419 52.345C124.702 50.8923 123.795 48.7664 123.692 45.9717C123.669 45.4051 123.658 44.4785 123.658 43.194C123.658 41.8946 123.669 40.9488 123.692 40.3566C123.795 37.6258 124.71 35.517 126.444 34.0344C128.183 32.5518 130.534 31.8105 133.499 31.8105C135.368 31.8105 137.044 32.1173 138.527 32.7308C140.009 33.34 141.181 34.2219 142.046 35.3807C142.908 36.5352 143.36 37.9006 143.4 39.479C143.4 39.6665 143.332 39.8241 143.196 39.9477C143.059 40.0733 142.898 40.1351 142.71 40.1351H138.228C137.932 40.1351 137.717 40.0797 137.581 39.9647C137.444 39.8518 137.325 39.6367 137.223 39.3171C136.927 38.2904 136.477 37.5704 135.877 37.1614C135.28 36.7482 134.488 36.5394 133.499 36.5394C131.124 36.5394 129.895 37.8665 129.81 40.5185C129.787 41.0873 129.776 41.9585 129.776 43.1343C129.776 44.3102 129.787 45.2048 129.81 45.8183C129.895 48.4661 131.124 49.7889 133.499 49.7889C134.464 49.7889 135.257 49.5801 135.877 49.1584C136.501 48.7387 136.95 48.023 137.223 47.0112C137.302 46.7002 137.415 46.4872 137.564 46.3721C137.711 46.2529 137.932 46.1932 138.228 46.1932H142.71C142.898 46.1932 143.059 46.2571 143.196 46.3807C143.332 46.5063 143.4 46.6661 143.4 46.8578C143.36 48.432 142.908 49.7974 142.046 50.9562C141.181 52.1107 140.009 52.9926 138.527 53.6061C137.044 54.2132 135.368 54.5178 133.499 54.5178Z"
                    fill="white"
                  />
                  <path
                    d="M147.401 54.2076C147.19 54.2076 147.005 54.1288 146.847 53.969C146.694 53.8114 146.617 53.626 146.617 53.4152V32.9574C146.617 32.7252 146.687 32.5292 146.83 32.3694C146.977 32.2118 147.167 32.1309 147.401 32.1309H162.286C162.519 32.1309 162.712 32.2118 162.866 32.3694C163.023 32.5292 163.104 32.7252 163.104 32.9574V36.0759C163.104 36.3102 163.023 36.4998 162.866 36.6467C162.712 36.7895 162.519 36.8598 162.286 36.8598H152.326V40.8644H161.596C161.822 40.8644 162.016 40.9454 162.176 41.103C162.333 41.2627 162.414 41.4587 162.414 41.6909V44.5538C162.414 44.7881 162.333 44.9841 162.176 45.1417C162.016 45.3015 161.822 45.3803 161.596 45.3803H152.326V49.4787H162.542C162.768 49.4787 162.962 49.5596 163.121 49.7172C163.279 49.8706 163.36 50.0645 163.36 50.2966V53.4152C163.36 53.6495 163.279 53.8391 163.121 53.986C162.962 54.1351 162.768 54.2076 162.542 54.2076H147.401Z"
                    fill="white"
                  />
                </svg>
              </div>
            </div>
          </div>

          <div className={s.footerBlock}>
            <div className={s.footerContent}>
              <div className={s.documents}>
                <div className={s.aboutPrivacyPolicy}>
                  <a href="#" target="_blank">
                    Privacy Policy
                  </a>
                </div>
                <div className={s.aboutOferta}>
                  <a href="#" target="_blank">
                    Terms and Conditions
                  </a>
                </div>
                <div className={s.aboutIicense}>
                  <a href="#" target="_blank">
                    License
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className={s.footerBlock}>
            <div className={s.contactInfo}>
              <div className={s.adress}>
                <a target="_blank" href="https://goo.gl/maps/612L7Qbh4srePA9M6">
                  Address:
                  <br />
                  Canada
                </a>
              </div>

              <div className={s.phone}>
                <a href="tel:+18563131236">Tel: +18563131236</a>
              </div>

              <div className={s.email}>
                <a href="mailto: support@paycenter-finance.com">
                  Email: support@paycenter-finance.com
                </a>
              </div>
            </div>
            <div className={s.footerLogos}>
              <div className={s.footerLogoItem}>
                <svg
                  width="60.1"
                  height="23.1"
                  viewBox="0 0 601 231"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M410.5 131.2L416.5 133C416.1 134.7 415.5 136.1 414.6 137.2C413.7 138.3 412.7 139.2 411.4 139.7C410.1 140.3 408.5 140.6 406.5 140.6C404.1 140.6 402.2 140.3 400.6 139.6C399.1 138.9 397.8 137.7 396.7 135.9C395.6 134.2 395 131.9 395 129.2C395 125.6 396 122.8 397.9 120.8C399.8 118.8 402.6 117.9 406.1 117.9C408.9 117.9 411 118.5 412.6 119.6C414.2 120.7 415.4 122.4 416.1 124.8L410.1 126.1C409.9 125.4 409.7 124.9 409.4 124.6C409 124.1 408.6 123.7 408 123.4C407.4 123.1 406.8 123 406.2 123C404.7 123 403.5 123.6 402.7 124.9C402.1 125.8 401.8 127.3 401.8 129.2C401.8 131.6 402.2 133.3 402.9 134.2C403.6 135.1 404.7 135.6 406 135.6C407.3 135.6 408.3 135.2 408.9 134.5C409.7 133.6 410.2 132.6 410.5 131.2Z"
                    fill="white"
                  />
                  <path
                    d="M418.898 129.201C418.898 125.601 419.898 122.801 421.898 120.801C423.898 118.801 426.698 117.801 430.298 117.801C433.998 117.801 436.798 118.801 438.798 120.701C440.798 122.701 441.798 125.401 441.798 129.001C441.798 131.601 441.398 133.701 440.498 135.301C439.598 136.901 438.398 138.201 436.698 139.101C435.098 140.001 432.998 140.501 430.598 140.501C428.098 140.501 426.098 140.101 424.398 139.301C422.798 138.501 421.498 137.301 420.398 135.501C419.298 133.701 418.898 131.801 418.898 129.201ZM425.698 129.201C425.698 131.401 426.098 133.001 426.898 134.001C427.698 135.001 428.798 135.501 430.298 135.501C431.798 135.501 432.898 135.001 433.698 134.101C434.498 133.101 434.898 131.401 434.898 129.001C434.898 126.901 434.498 125.401 433.598 124.501C432.798 123.501 431.598 123.101 430.198 123.101C428.798 123.101 427.698 123.601 426.898 124.601C426.098 125.401 425.698 127.001 425.698 129.201Z"
                    fill="white"
                  />
                  <path
                    d="M445.302 118.201H454.202L457.602 131.601L461.002 118.201H469.902V140.201H464.302V123.401L460.002 140.201H455.002L450.702 123.401V140.201H445.102V118.201H445.302Z"
                    fill="white"
                  />
                  <path
                    d="M474.301 118.201H485.601C488.101 118.201 489.901 118.801 491.101 120.001C492.301 121.201 492.901 122.801 492.901 125.001C492.901 127.201 492.201 129.001 490.901 130.201C489.601 131.501 487.501 132.101 484.801 132.101H481.101V140.301H474.301V118.201ZM481.101 127.601H482.801C484.101 127.601 485.001 127.401 485.601 126.901C486.101 126.401 486.401 125.901 486.401 125.201C486.401 124.501 486.201 123.901 485.701 123.401C485.201 122.901 484.401 122.701 483.101 122.701H481.201V127.601H481.101Z"
                    fill="white"
                  />
                  <path
                    d="M496.5 118.201H503.3V134.801H513.9V140.201H496.5V118.201Z"
                    fill="white"
                  />
                  <path
                    d="M517.301 118.201H524.101V140.201H517.301V118.201Z"
                    fill="white"
                  />
                  <path
                    d="M542.501 136.502H534.801L533.701 140.102H526.801L535.101 118.102H542.501L550.801 140.102H543.701L542.501 136.502ZM541.101 131.802L538.701 123.902L536.301 131.802H541.101Z"
                    fill="white"
                  />
                  <path
                    d="M552.898 118.201H559.198L567.498 130.401V118.201H573.898V140.201H567.498L559.298 128.101V140.201H552.898V118.201Z"
                    fill="white"
                  />
                  <path
                    d="M576.898 118.201H597.598V123.601H590.698V140.201H583.898V123.601H576.998V118.201H576.898Z"
                    fill="white"
                  />
                  <path
                    d="M328.701 151.701L348.401 145.501L342.001 131.301C337.301 137.201 332.601 144.801 328.701 151.701ZM322.001 86.8008L283.401 0.800781L0.800781 16.8008L79.1008 230.401L229.801 182.901C220.201 169.101 216.701 152.701 227.801 144.701C240.201 135.701 258.901 146.101 270.801 160.801C282.301 141.601 314.601 96.9008 322.001 86.8008Z"
                    fill="white"
                  />
                  <path
                    d="M274.595 62.9508C286.395 62.9508 295.995 53.9508 295.995 42.7508C295.995 31.5508 286.395 22.5508 274.595 22.5508C262.795 22.5508 253.195 31.5508 253.195 42.7508C253.195 53.8508 262.795 62.9508 274.595 62.9508ZM257.095 71.6508H292.095V163.151H257.095V71.6508Z"
                    fill="black"
                  />
                  <path
                    d="M239.796 98.3507C240.096 98.4507 240.296 98.3507 240.296 98.0507V74.8507C240.296 74.5507 240.096 74.2506 239.796 74.0506C239.796 74.0506 235.196 71.0506 221.196 70.2506C220.496 69.8506 208.196 69.7506 204.896 70.2506C153.896 74.3506 151.996 111.251 151.996 112.851V121.851C151.996 122.951 151.996 160.651 204.896 163.951C210.096 164.351 220.196 163.951 221.196 163.951C233.396 163.951 241.796 160.251 241.796 160.251C242.096 160.151 242.296 159.851 242.296 159.551V137.851C242.296 137.551 242.096 137.451 241.896 137.651C241.896 137.651 238.096 140.651 221.496 142.351C216.796 142.851 214.496 142.651 212.796 142.351C189.196 138.351 188.096 121.151 188.096 121.151C188.096 120.851 187.996 120.351 187.996 120.151V113.551C187.996 113.251 187.996 112.751 188.096 112.551C188.096 112.551 189.696 94.0507 212.796 92.0507H221.496C231.596 93.3507 239.796 98.3507 239.796 98.3507Z"
                    fill="black"
                  />
                  <path
                    d="M54.7969 162.85C54.7969 163.15 54.9969 163.35 55.2969 163.35H89.3969C89.6969 163.35 89.8969 163.15 89.8969 162.85V136.25C89.8969 135.95 90.0969 135.75 90.3969 135.75C90.3969 135.75 144.897 139.65 144.897 103.15C144.897 74.2498 110.697 71.1499 99.4969 71.6499C99.2969 71.6499 55.2969 71.6499 55.2969 71.6499C54.9969 71.6499 54.7969 71.8499 54.7969 72.1499V162.85ZM89.2969 115.45V91.5499H97.6969C97.6969 91.5499 109.797 92.0499 110.797 100.35C110.997 100.95 110.997 105.05 110.797 105.25C109.197 114.95 98.6969 115.45 98.6969 115.45H89.2969Z"
                    fill="black"
                  />
                  <path
                    d="M275.6 207.3C278.4 207.3 280.6 207.3 284.2 205.7C296.6 199.2 338.5 96.9003 382.7 65.4003C383 65.2003 383.3 64.9003 383.5 64.6003C383.8 64.2003 383.8 63.8003 383.8 63.8003C383.8 63.8003 383.8 61.7003 377.3 61.7003C338 60.6003 297.1 143.1 275.6 175.7C275.3 176.1 273.9 175.7 273.9 175.7C273.9 175.7 259.5 158.7 247 152.2C246.7 152.1 245.3 151.6 243.8 151.7C242.8 151.7 237 152.9 234.3 155.7C231.1 159.1 231.2 161 231.2 165.1C231.2 165.4 231.4 166.8 231.8 167.5C234.9 172.9 248.8 192.1 260.3 202.7C262 204 264.7 207.3 275.6 207.3Z"
                    fill="white"
                  />
                  <path
                    d="M394.301 34.502H427.001C433.501 34.502 438.701 35.402 442.601 37.102C446.601 38.902 449.901 41.402 452.401 44.602C455.001 47.902 456.901 51.702 458.001 56.002C459.201 60.302 459.801 64.9019 459.801 69.8019C459.801 77.4019 458.901 83.302 457.201 87.502C455.501 91.702 453.101 95.202 450.001 98.102C446.901 100.902 443.601 102.802 440.101 103.802C435.301 105.102 430.901 105.702 427.001 105.702H394.301V34.502ZM416.301 50.602V89.502H421.701C426.301 89.502 429.601 89.002 431.501 88.002C433.401 87.002 435.001 85.202 436.101 82.702C437.201 80.202 437.801 76.0019 437.801 70.3019C437.801 62.7019 436.601 57.6019 434.101 54.8019C431.601 52.0019 427.601 50.602 421.801 50.602H416.301Z"
                    fill="white"
                  />
                  <path
                    d="M465.902 82.2012L486.902 80.9012C487.402 84.3012 488.302 86.9012 489.702 88.7012C492.002 91.6012 495.202 93.0012 499.402 93.0012C502.502 93.0012 505.002 92.3012 506.702 90.8012C508.402 89.3012 509.302 87.6012 509.302 85.7012C509.302 83.9012 508.502 82.2012 506.902 80.7012C505.302 79.2012 501.502 77.9012 495.602 76.6012C485.902 74.4012 479.102 71.5012 474.902 67.9012C470.702 64.3012 468.702 59.7012 468.702 54.1012C468.702 50.4012 469.802 47.0012 471.902 43.7012C474.002 40.4012 477.202 37.9012 481.502 36.0012C485.802 34.1012 491.602 33.2012 499.002 33.2012C508.102 33.2012 515.102 34.9012 519.802 38.3012C524.602 41.7012 527.402 47.1012 528.302 54.5012L507.502 55.7012C506.902 52.5012 505.802 50.2012 504.002 48.7012C502.202 47.2012 499.802 46.5012 496.702 46.5012C494.102 46.5012 492.202 47.0012 490.902 48.1012C489.602 49.2012 489.002 50.5012 489.002 52.1012C489.002 53.2012 489.502 54.3012 490.602 55.2012C491.602 56.1012 494.102 57.0012 498.002 57.8012C507.602 59.9012 514.502 62.0012 518.702 64.1012C522.902 66.2012 525.902 68.9012 527.802 72.0012C529.702 75.1012 530.602 78.7012 530.602 82.6012C530.602 87.2012 529.302 91.4012 526.802 95.2012C524.302 99.1012 520.702 102.001 516.202 104.001C511.702 106.001 505.902 107.001 499.002 107.001C486.902 107.001 478.502 104.701 473.802 100.001C469.202 95.3012 466.602 89.4012 465.902 82.2012Z"
                    fill="white"
                  />
                  <path
                    d="M536.199 82.2012L557.199 80.9012C557.699 84.3012 558.599 86.9012 559.999 88.7012C562.299 91.6012 565.499 93.0012 569.699 93.0012C572.799 93.0012 575.299 92.3012 576.999 90.8012C578.699 89.3012 579.599 87.6012 579.599 85.7012C579.599 83.9012 578.799 82.2012 577.199 80.7012C575.599 79.2012 571.799 77.9012 565.899 76.6012C556.199 74.4012 549.399 71.5012 545.199 67.9012C541.099 64.3012 538.999 59.7012 538.999 54.1012C538.999 50.4012 540.099 47.0012 542.199 43.7012C544.299 40.4012 547.499 37.9012 551.799 36.0012C556.099 34.1012 561.899 33.2012 569.299 33.2012C578.399 33.2012 585.399 34.9012 590.099 38.3012C594.899 41.7012 597.699 47.1012 598.599 54.5012L577.799 55.7012C577.199 52.5012 576.099 50.2012 574.299 48.7012C572.499 47.2012 570.099 46.5012 566.999 46.5012C564.399 46.5012 562.499 47.0012 561.199 48.1012C559.899 49.2012 559.299 50.5012 559.299 52.1012C559.299 53.2012 559.799 54.3012 560.899 55.2012C561.899 56.1012 564.399 57.0012 568.299 57.8012C577.899 59.9012 584.799 62.0012 588.999 64.1012C593.199 66.2012 596.199 68.9012 598.099 72.0012C599.999 75.1012 600.899 78.7012 600.899 82.6012C600.899 87.2012 599.599 91.4012 597.099 95.2012C594.599 99.1012 590.999 102.001 586.499 104.001C581.999 106.001 576.199 107.001 569.299 107.001C557.199 107.001 548.799 104.701 544.099 100.001C539.399 95.3012 536.799 89.4012 536.199 82.2012Z"
                    fill="white"
                  />
                </svg>
              </div>
              <div className={s.footerLogoItem}>
                <FaCcVisa className={s.iconPayments} />
              </div>
              <div className={s.footerLogoItem}>
                <FaCcMastercard className={s.iconPayments} />
              </div>
              <div className={s.footerLogoItem}>
                <FaCcApplePay className={s.iconPayments} />
              </div>
              <div className={s.footerLogoItem}>
                <FaGooglePay className={s.iconPayments} />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={s.copyright}>
        <div className={s.container}>
          <div className={s.copyrightText}>
            <div>© 2023 PAYCENTER FINANCE, INC</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
