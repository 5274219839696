import React, { useState, useEffect } from "react";
import s from "./Slider.module.css";
import one from "../../assets/Slider/1.webp";
import two from "../../assets/Slider/2.webp";
import three from "../../assets/Slider/3.webp";

const Slider = () => {
  const [currentImage, setCurrentImage] = useState(0);
  const images = [
    {
      src: one,
      captionOverlayText: "Your payment and cross-border solutions provider",
    },
    {
      src: two,
      captionOverlayText:
        "Paycenter Finance accommodates anyone involved in making or receiving payments",
    },
    {
      src: three,
      captionOverlayText:
        "Paycenter Finance easy money transfers, real-time currency conversion, and fee-free spending worldwide",
    },
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImage((prevImage) => (prevImage + 1) % images.length);
    }, 7000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const handlePrevSlide = () => {
    setCurrentImage((prevImage) =>
      prevImage === 0 ? images.length - 1 : prevImage - 1
    );
  };

  const handleNextSlide = () => {
    setCurrentImage((prevImage) => (prevImage + 1) % images.length);
  };

  return (
    <div className={s.slider} id="header">
      <div className={s.slideButtons}>
        <button className={s.prevButton} onClick={handlePrevSlide}>
          &lt;
        </button>
        <button className={s.nextButton} onClick={handleNextSlide}>
          &gt;
        </button>
      </div>
      <div className={s.slideIndicators}>
        {images.map((_, index) => (
          <div
            key={index}
            className={`${s.indicator} ${
              index === currentImage ? s.active : ""
            }`}
          />
        ))}
      </div>
      <div className={s.slidesContainer}>
        {images.map((image, index) => (
          <div
            key={index}
            className={`${s.slide} ${
              index === currentImage ? s.currentSlide : ""
            }`}
            style={{
              backgroundImage: `url(${image.src})`,
            }}
          >
            <div className={s.caption}>{image.caption}</div>

            <div className={s.captionOverlay}>{image.captionOverlayText}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Slider;
