import React, { useState } from "react";
import { BsFillArrowDownCircleFill } from "react-icons/bs";
import { RiCloseCircleFill } from "react-icons/ri";
import s from "./Accordion.module.css";

const Accordion = ({ sections }) => {
  const [activeIndex, setActiveIndex] = useState(null);

  const handleSectionClick = (index) => {
    setActiveIndex(index === activeIndex ? null : index);
  };

  return (
    <div className={s["accordion-container"]}>
      <div className={s.accordion}>
        {sections.map((section, index) => (
          <div className={s["accordion-section"]} key={index}>
            <button
              className={`${s["accordion-title"]} ${
                index === activeIndex ? s.active : ""
              }`}
              onClick={() => handleSectionClick(index)}
            >
              <span className={s["title-text"]}>{section.title}</span>
              <span className={s["icon"]}>
                {index === activeIndex ? (
                  <RiCloseCircleFill className={s.iconClose} />
                ) : (
                  <BsFillArrowDownCircleFill className={s.iconOpen} />
                )}
              </span>
            </button>
            <div
              className={`${s["accordion-content"]} ${
                index === activeIndex ? s.active : ""
              }`}
            >
              {section.content}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Accordion;
