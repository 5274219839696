import React, { useEffect, useState } from "react";
import s from "./Card.module.css";
import Modal from "./Modal";
import Popup from "./Popup";

import Acquiring from "../../assets/Popup/acquiring.webp";
import Cards from "../../assets/Popup/cards.webp";
import QR from "../../assets/Popup/qr.webp";
import WhiteLabel from "../../assets/Popup/whitelabel.webp";

import acquiring from "../../assets/Modal/acquiring.webp";
import cards from "../../assets/Modal/cards.webp";
import qr from "../../assets/Modal/qr.webp";
import whitelabel from "../../assets/Modal/whitelabel.webp";

const state = {
  cards: [
    {
      id: 1,
      title: "Internet acquiring",
      descriptionOne:
        "This is a payment service that allows your organization to accept bank cards of international payment systems as payment for its goods/services",
      image: Acquiring,

      popups: {
        title: "Internet acquiring",
        photo: acquiring,
        infoTextOne:
          "This is a payment service that allows your organization to accept bank cards of international payment systems as payment for its goods/services",
        infoTextTwo: "This method will allow your company to:",
        infoTextThree:
          "— Increases the competitiveness of your organization, increases turnover by attracting new customers — bank card holders",
        infoTextFour:
          "— Bank card holders are easier to decide on unplanned purchases",
        infoTextFive:
          "- Security of payments (Reduces the risk of accepting counterfeit banknotes, losing or stealing money at a point of sale, fires, etc.)",
        infoTextSix: "- Quick connection with a minimum package of documents",
        infoTextSeven: "— Provision of technical support — Around the clock",
      },
    },
    {
      id: 2,
      title: "Invoicing and payment by Payment system",
      descriptionOne:
        "A payment link is a full-fledged form of payment that does not require a website",
      image: Cards,

      popups: {
        title: "Invoicing and payment by Payment system",
        photo: cards,
        infoTitle: "Invoicing and payment by Payment system",
        infoTextOne:
          "A payment link is a full-fledged form of payment that does not require a website. Create a link in just a couple of clicks and send it wherever you communicate with customers. Why accept online payments without a website? The answer is simple - Sell immediately while communicating in social networks or instant messengers and increase the number of sales channels, and with them - customers!",
        infoTextTwo:
          "- Accept payment during or immediately after a telephone consultation so as not to miss the buyer",
        infoTextThree:
          "- Include payment links in emails to reduce abandoned carts",
        infoTextFour:
          "- Form of payment in a couple of seconds, without using a single line of code",
        infoTextFive:
          "- All links are unique. No need to figure out which client the payment came from",
        infoTextSix:
          "- Statuses and payment information are located in the user-friendly interface of your personal account",
      },
    },
    {
      id: 3,
      title: "Payment by QR code",
      descriptionOne: "Payment by QR code",
      image: QR,

      popups: {
        title: "Payment by QR code",
        photo: qr,
        infoTitle: "Payment by QR code",
        infoTextOne: "— The buyer will be able to pay without a card and cash",
        infoTextTwo: "— Connection remotely, from one working day",
        infoTextThree:
          "— No complex integration with a system or website is required",
        infoTextFour: "— Payment Security",
      },
    },
    {
      id: 4,
      title: "White label solution",
      descriptionOne:
        "The solution can work as a main processing, as a pre-processing or as a payment router",
      image: WhiteLabel,

      popups: {
        title: "White label solution",
        photo: whitelabel,
        infoTitle: "White label solution",
        infoTextOne:
          "The solution can work as a main processing, as a pre-processing or as a payment router",
        infoTextTwo: "— Payouts to bank cards",
        infoTextThree: "— IFRAME payment",
        infoTextFour: "— Internet acquiring",
        infoTextFive: "— Recurring payments",
        infoTextSix: "— Payment with QR code",
      },
    },
  ],
};

const Card = () => {
  const [modalActive, setModalActive] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  useEffect(() => {
    function handleEscapeKey(event) {
      if (event.code === "Escape") {
        document.body.style.overflow = "visible";
        setSelectedItem(null);
        setModalActive(false);
      }
    }
    document.addEventListener("keydown", handleEscapeKey);
    return () => document.removeEventListener("keydown", handleEscapeKey);
  }, []);

  const openModal = (popups) => {
    document.body.style.overflow = "hidden";
    setModalActive(true);
    setSelectedItem(popups);
  };

  const closeModal = () => {
    document.body.style.overflow = "visible";
    setModalActive(false);
    setSelectedItem(null);
  };

  return (
    <div className={s.vertical}>
      <div className={s.verticalBody}>
        <div className={s.verticalRow}>
          {state.cards.map((card) => (
            <div key={card.id} className={s.verticalColumn}>
              <div className={s.verticalItem}>
                <div className={s.verticalLabel}>
                  <img src={card.image} alt="" />
                </div>
                <div className={s.verticalText}>
                  <p>{card.descriptionOne}</p>
                </div>

                <button
                  onClick={() => openModal(card)}
                  className={s.verticalBtn}
                >
                  More
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
      <Modal active={selectedItem !== null} modalActive={modalActive}>
        {selectedItem && (
          <Popup popups={selectedItem.popups} closeModal={closeModal} />
        )}
      </Modal>
    </div>
  );
};

export default Card;
