import { MdPayments } from "react-icons/md";
import { GiPayMoney } from "react-icons/gi";
import { MdOutlineCurrencyExchange } from "react-icons/md";
import { BsCurrencyBitcoin } from "react-icons/bs";
import { FaQrcode } from "react-icons/fa";
import { GiTakeMyMoney } from "react-icons/gi";
import s from "./PanelOne.module.css";

const PanelOne = () => {
  return (
    <div className={s.section} id="services">
      <div className={s.container}>
        <div className={s.sectionHeader}>
          <h2 className={s.sectionTitle}>OUR SERVICES</h2>
        </div>
        <div className={s.services}>
          <div className={`${s.servicesItem} ${s.servicesItemBorder}`}>
            <MdPayments className={s.servicesIcon} />
            <div className={s.servicesTitle}>internet acquiring</div>
            <div className={s.servicesText}>
              Convenient online payments for any business
            </div>
          </div>
          <hr className={s.hrOne} />
          <div className={`${s.servicesItem} ${s.servicesItemBorder}`}>
            <GiPayMoney className={s.servicesIcon} />
            <div className={s.servicesTitle}>Payout Service</div>
            <div className={s.servicesText}>
              Issuance of funds to your client on bank cards
            </div>
          </div>
          <hr className={s.hrTwo} />
          <div className={`${s.servicesItem} ${s.servicesItemBorder}`}>
            <MdOutlineCurrencyExchange className={s.servicesIcon} />
            <div className={s.servicesTitle}>Online transfers</div>
            <div className={s.servicesText}>
              Instant money transfers from card to card
            </div>
          </div>
          <hr className={s.hrThree} />
          <div className={`${s.servicesItem} ${s.servicesItemBorderFivteen}`}>
            <BsCurrencyBitcoin className={s.servicesIcon} />
            <div className={s.servicesTitle}>Cryptocurrency Exchange</div>
            <div className={s.servicesText}>Instant currency exchange 24/7</div>
          </div>
          <hr className={s.hrTwo} />
          <div className={s.servicesItem}>
            <FaQrcode className={s.servicesIcon} />
            <div className={s.servicesTitle}>QR payment widget</div>
            <div className={s.servicesText}>
              Accepting payments without integration
            </div>
          </div>
          <hr className={s.hrOne} />
          <div className={s.servicesItem}>
            <GiTakeMyMoney className={s.servicesIcon} />
            <div className={s.servicesTitle}>Alternative payment methods</div>
            <div className={s.servicesText}>
              Acceptance of payments through terminals, wallets, cell phone
              balance
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PanelOne;
