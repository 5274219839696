import s from "./Modal.module.css";

const Modal = ({ active, closeModal, children }) => {
  return (
    <div
      className={active ? `${s.model} ${s.active}` : s.model}
      onClick={closeModal}
    >
      <div
        className={active ? `${s.modelContent} ${s.active}` : s.modelContent}
        onClick={(e) => e.stopPropagation()}
      >
        {children}
      </div>
    </div>
  );
};

export default Modal;
