import React from "react";
import Accordion from "./Accordion";
import s from "./FAQPage.module.css";

const FAQPage = () => {
  const faqSections = [
    {
      title: "What payment methods does the service support?",
      content:
        "The service supports the most popular payment methods: accepting payments by bank cards of Visa and Mastercard payment systems, as well as one-click payments using Apple and Google Pay",
    },
    {
      title: "Is it possible to accept international payments?",
      content:
        "Yes, you can connect Internet acquiring and make payments in dollars, euros and other currencies - we work with any bank in the world",
    },
    {
      title: "How much does it cost to connect to the service?",
      content:
        "Connection is free, we take only a commission from each transaction. Its size depends on the turnover and characteristics of the business. In addition, we provide technical support for free and assign you a personal manager who is available around the clock",
    },
    {
      title: "What documents are needed to connect?",
      content:
        "The list of required documents will be provided by your personal manager",
    },
    {
      title: "Is it possible to connect Internet acquiring without a website?",
      content:
        "We provide the opportunity to issue invoices for payment from your personal account via a link in social networks, in instant messengers, via SMS or e-mail. The payment link can be placed in the form of a QR code, including on a physical medium: booklet, banner, leaflet",
    },
  ];

  return (
    <div className={s.faqPage} id="faq">
      <div className={s.container}>
        <div className={s.faqHeader}>
          <h2 className={s.faqTitle}>FAQ</h2>
        </div>
        <Accordion sections={faqSections} />
      </div>
    </div>
  );
};

export default FAQPage;
