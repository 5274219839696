import s from "./About.module.css";
import team from "../../assets/About/team.webp";

function About() {
  return (
    <div className={s.about} id="about">
      <div className={s.container}>
        {/* ref на данный раздел */}
        <div className={s.aboutHeader}>
          <h2 className={s.aboutTitle}>ABOUT US</h2>
        </div>
        <div className={s.aboutInner}>
        <img className={s.aboutImage} src={team} alt="Изображение Команды" />
          <div className={s.aboutContent}>
            <div className={s.aboutInfo}>
              <p>
              <b>Paycenter Finance</b> payment aggregator is your assistant in the world of electronic payments and a single solution for your business
              </p>
              <p>
              We provide services for accepting payments in various ways: on your website, in a mobile application, at the checkout (in a cafe, restaurant or store) with a wide range of options for every taste and color
              </p>
            </div>
          </div>

      
        </div>
{/*         <span className={s.aboutTitle}>Юридическая информация</span>
        <div className={s.documents}>
          <div className={s.aboutPrivacyPolicy}>
            <a href="https://ay-money.kz/privacy-policy.pdf" target="_blank">
              Политика конфиденциальности
            </a>
          </div>
          <div className={s.aboutOferta}>
            <a href="https://ay-money.kz/oferta.pdf" target="_blank">
              Публичный договор оферты
            </a>
            <div className={s.aboutIicense}>
              <a href="https://ay-money.kz/license.pdf" target="_blank">
                Лицензия
              </a>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
}

export default About;
