import "./App.css";
import About from "./components/About/About";
import Footer from "./components/Footer/Footer";
import Header from "./components/Header/Header";
import Contact from "./components/Contact/Contact";
import Services from "./components/Services/Services";
import Slider from "./components/Slider/Slider";
import PanelOne from "./components/PanelOne/PanelOne";
import FAQPage from "./components/FAQPage/FAQPage";

function App() {
  return (
    <div className="App">
      <Header />
      <Slider />
      <PanelOne />
      <Services />
      <About />
      <Contact />
      <FAQPage />
      <Footer />
    </div>
  );
}

export default App;
