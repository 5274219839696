import { BsFillTelephoneFill } from "react-icons/bs";
import { MdEmail } from "react-icons/md";
import { FaMapMarkerAlt } from "react-icons/fa";
import s from "./Contact.module.css";

function Contact() {
  return (
    <div className={s.map} id="contact">
      <div className={s.container}>
        <div className={s.mapHeader}>
          <h2 className={s.mapTitle}>Contacts</h2>
        </div>
        <div className={s.mapInner}>
          <div className={s.mapContent}>
            <div className={s.contactInfo}>
              <div className={s.adress}>
                <a target="_blank" href="https://goo.gl/maps/612L7Qbh4srePA9M6">
                  <FaMapMarkerAlt className={s.iconMap} />
                  Address:
                  <br />
                  Canada
                </a>
              </div>
              <div className={s.phone}>
                <a href="tel:+18563131236">
                  <BsFillTelephoneFill className={s.iconTel} />
                  Tel: +18563131236
                </a>
              </div>
              <div className={s.email}>
                <a href="mailto: support@paycenter-finance.com">
                  <MdEmail className={s.iconMail} />
                  Email: support@paycenter-finance.com
                </a>
              </div>
            </div>
          </div>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d83327.37148405479!2d-123.20630404118532!3d49.25781819006825!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x548673f143a94fb3%3A0xbb9196ea9b81f38b!2z0JLQsNC90LrRg9Cy0LXRgCwg0JHRgNC40YLQsNC90YHQutCw0Y8g0JrQvtC70YPQvNCx0LjRjywg0JrQsNC90LDQtNCw!5e0!3m2!1sru!2skz!4v1692537157223!5m2!1sru!2skz"
            width="800"
            height="400"
            frameborder="0"
          ></iframe>
        </div>
      </div>
    </div>
  );
}

export default Contact;
