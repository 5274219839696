import { useEffect, useState } from "react";
import { BsCurrencyExchange } from "react-icons/bs";
import { Link } from "react-scroll";
import s from "./Header.module.css";

function Header() {
  const [isActive, setIsActive] = useState(false);
  const [fix, setFix] = useState(false);

  const handleClick = () => {
    setIsActive(!isActive);
  };

  const handleNavLinkClick = () => {
    setIsActive(false);
  };

  const setFixed = () => {
    if (window.scrollY >= 70) {
      setFix(true);
    } else {
      setFix(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", setFixed);

    return () => {
      window.removeEventListener("scroll", setFixed);
    };
  }, []);

  return (
    <header className={fix ? `${s.header} ${s.fixed}` : `${s.header}`}>
      <div className={s.container}>
        <div className={s.headerInner}>
          <Link
            className={s.headerLogo}
            to="header"
            smooth={true}
            duration={500}
            offset={-150}
            onClick={handleNavLinkClick}
          >
            <svg
              width="116"
              height="34"
              viewBox="0 0 116 34"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0.478584 13.6698C0.349835 13.6698 0.236691 13.6217 0.140454 13.5241C0.046818 13.4279 0 13.3148 0 13.186V0.696001C0 0.554246 0.0429165 0.4346 0.13005 0.337062C0.219785 0.240825 0.335529 0.191406 0.478584 0.191406H5.93028C7.59753 0.191406 8.90583 0.571152 9.8526 1.33064C10.802 2.08754 11.2779 3.19686 11.2779 4.65993C11.2779 6.12429 10.802 7.21801 9.8526 7.94239C8.90583 8.66807 7.59753 9.02961 5.93028 9.02961H3.73504V13.186C3.73504 13.3148 3.68952 13.4279 3.59979 13.5241C3.50875 13.6217 3.39301 13.6698 3.25125 13.6698H0.478584ZM5.83145 6.27775C6.35815 6.27775 6.77301 6.1412 7.07473 5.86679C7.37644 5.58979 7.5273 5.18013 7.5273 4.63912C7.5273 4.13973 7.38815 3.73657 7.11114 3.42705C6.83673 3.11883 6.41017 2.96407 5.83145 2.96407H3.67782V6.27775H5.83145Z"
                fill="black"
              />
              <path
                d="M12.3224 13.6698C12.221 13.6698 12.13 13.6282 12.0467 13.5449C11.9635 13.4617 11.9219 13.3681 11.9219 13.264C11.9219 13.1886 11.9271 13.1314 11.9375 13.0924L16.4268 0.753223C16.5543 0.378678 16.8118 0.191406 17.1967 0.191406H20.1983C20.5832 0.191406 20.8394 0.378678 20.9682 0.753223L25.4523 13.0924C25.4653 13.1314 25.4731 13.1886 25.4731 13.264C25.4731 13.3681 25.4315 13.4617 25.3482 13.5449C25.265 13.6282 25.1714 13.6698 25.0673 13.6698H22.5652C22.2557 13.6698 22.0372 13.5293 21.9097 13.2484L21.2179 11.3809H16.1771L15.48 13.2484C15.3513 13.5293 15.1328 13.6698 14.8246 13.6698H12.3224ZM16.9834 8.4886H20.4115L18.6949 3.58311L16.9834 8.4886Z"
                fill="black"
              />
              <path
                d="M30.8741 13.6698C30.7454 13.6698 30.6323 13.6217 30.536 13.5241C30.4424 13.4279 30.3956 13.3148 30.3956 13.186V9.08683L25.9478 0.810445C25.9088 0.74542 25.8906 0.673892 25.8906 0.597163C25.8906 0.493122 25.9322 0.399486 26.0155 0.316254C26.0987 0.233022 26.1923 0.191406 26.2964 0.191406H28.835C29.0144 0.191406 29.1627 0.236924 29.2771 0.326658C29.3916 0.417693 29.4748 0.51393 29.5268 0.617971L32.2059 5.45063L34.8797 0.617971C34.9317 0.51393 35.0149 0.417693 35.1294 0.326658C35.2464 0.236924 35.3947 0.191406 35.5716 0.191406H38.1153C38.2194 0.191406 38.313 0.233022 38.3962 0.316254C38.4795 0.399486 38.5211 0.493122 38.5211 0.597163C38.5211 0.673892 38.5016 0.74542 38.4639 0.810445L34.0162 9.08683V13.186C34.0162 13.3291 33.9667 13.4448 33.8705 13.5345C33.773 13.6256 33.6533 13.6698 33.5116 13.6698H30.8741Z"
                fill="black"
              />
              <path
                d="M45.6841 13.8633C43.8491 13.8633 42.4081 13.4212 41.3612 12.5368C40.313 11.6499 39.759 10.352 39.6966 8.64573C39.6823 8.2998 39.6758 7.73408 39.6758 6.94988C39.6758 6.15657 39.6823 5.57915 39.6966 5.21761C39.759 3.55037 40.3182 2.26287 41.3768 1.35772C42.438 0.452574 43.8738 0 45.6841 0C46.8246 0 47.8481 0.187272 48.7533 0.561816C49.6584 0.93376 50.3737 1.47217 50.9017 2.17964C51.4284 2.88451 51.7041 3.71813 51.7288 4.6818C51.7288 4.79625 51.6872 4.89248 51.604 4.96791C51.5207 5.04464 51.4219 5.08236 51.3075 5.08236H48.5712C48.3904 5.08236 48.2591 5.04854 48.1759 4.97832C48.0926 4.90939 48.0198 4.77804 47.9574 4.58296C47.7766 3.95612 47.5022 3.51655 47.1355 3.26686C46.7713 3.01456 46.2875 2.88711 45.6841 2.88711C44.234 2.88711 43.4836 3.69732 43.4316 5.31645C43.4173 5.66368 43.4108 6.19559 43.4108 6.91346C43.4108 7.63134 43.4173 8.17755 43.4316 8.55209C43.4836 10.1686 44.234 10.9762 45.6841 10.9762C46.2732 10.9762 46.757 10.8488 47.1355 10.5913C47.5165 10.3351 47.7909 9.89811 47.9574 9.28037C48.0055 9.0905 48.0744 8.96045 48.1655 8.89022C48.2552 8.8174 48.3904 8.78098 48.5712 8.78098H51.3075C51.4219 8.78098 51.5207 8.82 51.604 8.89543C51.6872 8.97216 51.7288 9.06969 51.7288 9.18674C51.7041 10.1478 51.4284 10.9814 50.9017 11.6889C50.3737 12.3938 49.6584 12.9322 48.7533 13.3067C47.8481 13.6774 46.8246 13.8633 45.6841 13.8633Z"
                fill="black"
              />
              <path
                d="M54.1641 13.6698C54.0354 13.6698 53.9222 13.6217 53.826 13.5241C53.7324 13.4279 53.6855 13.3148 53.6855 13.186V0.696001C53.6855 0.554246 53.7285 0.4346 53.8156 0.337062C53.9053 0.240825 54.0211 0.191406 54.1641 0.191406H63.252C63.3938 0.191406 63.5121 0.240825 63.6058 0.337062C63.702 0.4346 63.7514 0.554246 63.7514 0.696001V2.59993C63.7514 2.74299 63.702 2.85873 63.6058 2.94847C63.5121 3.0356 63.3938 3.07852 63.252 3.07852H57.1709V5.52346H62.8307C62.9685 5.52346 63.0869 5.57288 63.1844 5.66912C63.2806 5.76665 63.3301 5.8863 63.3301 6.02805V7.77593C63.3301 7.91898 63.2806 8.03863 63.1844 8.13487C63.0869 8.2324 62.9685 8.28052 62.8307 8.28052H57.1709V10.7827H63.4081C63.5459 10.7827 63.6643 10.8321 63.7618 10.9283C63.8581 11.022 63.9075 11.1403 63.9075 11.2821V13.186C63.9075 13.3291 63.8581 13.4448 63.7618 13.5345C63.6643 13.6256 63.5459 13.6698 63.4081 13.6698H54.1641Z"
                fill="black"
              />
              <path
                d="M66.5216 13.6698C66.3928 13.6698 66.2797 13.6217 66.1834 13.5241C66.0898 13.4279 66.043 13.3148 66.043 13.186V0.696001C66.043 0.554246 66.0859 0.4346 66.173 0.337062C66.2628 0.240825 66.3785 0.191406 66.5216 0.191406H68.696C68.9938 0.191406 69.2123 0.314954 69.3514 0.560748L73.898 7.73951V0.696001C73.898 0.554246 73.9409 0.4346 74.028 0.337062C74.1178 0.240825 74.2335 0.191406 74.3766 0.191406H76.8215C76.9633 0.191406 77.079 0.240825 77.1701 0.337062C77.2598 0.4346 77.3053 0.554246 77.3053 0.696001V13.1704C77.3053 13.3096 77.2598 13.4279 77.1701 13.5241C77.079 13.6217 76.9633 13.6698 76.8215 13.6698H74.6471C74.3519 13.6698 74.1334 13.5489 73.9916 13.3057L69.4659 6.413V13.186C69.4659 13.3291 69.4165 13.4448 69.3202 13.5345C69.2266 13.6256 69.1082 13.6698 68.9665 13.6698H66.5216Z"
                fill="black"
              />
              <path
                d="M83.3924 13.6698C83.2494 13.6698 83.1297 13.6256 83.0335 13.5345C82.9399 13.4448 82.8931 13.3291 82.8931 13.186V3.33342H79.5013C79.3622 3.33342 79.2438 3.2892 79.1476 3.19816C79.0501 3.10843 79.002 2.99269 79.002 2.84963V0.696001C79.002 0.554246 79.0501 0.4346 79.1476 0.337062C79.2438 0.240825 79.3622 0.191406 79.5013 0.191406H89.9574C90.0991 0.191406 90.2175 0.240825 90.3111 0.337062C90.4073 0.4346 90.4568 0.554246 90.4568 0.696001V2.84963C90.4568 2.99269 90.4073 3.10843 90.3111 3.19816C90.2175 3.2892 90.0991 3.33342 89.9574 3.33342H86.5709V13.186C86.5709 13.3291 86.5214 13.4448 86.4252 13.5345C86.3277 13.6256 86.208 13.6698 86.0663 13.6698H83.3924Z"
                fill="black"
              />
              <path
                d="M92.6348 13.6698C92.5061 13.6698 92.3929 13.6217 92.2967 13.5241C92.2031 13.4279 92.1562 13.3148 92.1562 13.186V0.696001C92.1562 0.554246 92.1992 0.4346 92.2863 0.337062C92.376 0.240825 92.4918 0.191406 92.6348 0.191406H101.723C101.864 0.191406 101.983 0.240825 102.076 0.337062C102.173 0.4346 102.222 0.554246 102.222 0.696001V2.59993C102.222 2.74299 102.173 2.85873 102.076 2.94847C101.983 3.0356 101.864 3.07852 101.723 3.07852H95.6416V5.52346H101.301C101.439 5.52346 101.558 5.57288 101.655 5.66912C101.751 5.76665 101.801 5.8863 101.801 6.02805V7.77593C101.801 7.91898 101.751 8.03863 101.655 8.13487C101.558 8.2324 101.439 8.28052 101.301 8.28052H95.6416V10.7827H101.879C102.017 10.7827 102.135 10.8321 102.233 10.9283C102.329 11.022 102.378 11.1403 102.378 11.2821V13.186C102.378 13.3291 102.329 13.4448 102.233 13.5345C102.135 13.6256 102.017 13.6698 101.879 13.6698H92.6348Z"
                fill="black"
              />
              <path
                d="M104.986 13.6698C104.858 13.6698 104.745 13.6217 104.648 13.5241C104.555 13.4279 104.508 13.3148 104.508 13.186V0.696001C104.508 0.554246 104.551 0.4346 104.638 0.337062C104.728 0.240825 104.843 0.191406 104.986 0.191406H110.282C111.978 0.191406 113.302 0.576354 114.256 1.34625C115.214 2.11615 115.692 3.20207 115.692 4.60271C115.692 5.50135 115.481 6.26605 115.063 6.89679C114.647 7.52493 114.072 8.01262 113.341 8.35855L115.942 13.056C115.98 13.1327 115.999 13.2016 115.999 13.264C115.999 13.3681 115.96 13.4617 115.885 13.5449C115.808 13.6282 115.71 13.6698 115.593 13.6698H112.883C112.508 13.6698 112.244 13.4968 112.092 13.1496L109.975 8.97239H108.123V13.186C108.123 13.3291 108.074 13.4448 107.978 13.5345C107.884 13.6256 107.766 13.6698 107.624 13.6698H104.986ZM110.261 6.1425C110.813 6.1425 111.231 6.00595 111.52 5.73154C111.811 5.45453 111.957 5.07089 111.957 4.5819C111.957 4.09291 111.811 3.70146 111.52 3.40624C111.231 3.11233 110.813 2.96407 110.261 2.96407H108.123V6.1425H110.261Z"
                fill="black"
              />
              <path
                d="M16.4962 32.8964C16.3674 32.8964 16.2543 32.8482 16.158 32.7507C16.0644 32.6545 16.0176 32.5413 16.0176 32.4126V19.9226C16.0176 19.7808 16.0605 19.6612 16.1476 19.5636C16.2374 19.4674 16.3531 19.418 16.4962 19.418H25.4488C25.5906 19.418 25.7089 19.4674 25.8025 19.5636C25.8988 19.6612 25.9482 19.7808 25.9482 19.9226V21.9617C25.9482 22.1048 25.8988 22.2205 25.8025 22.3103C25.7089 22.3974 25.5906 22.4403 25.4488 22.4403H19.5601V25.1194H25.0639C25.2056 25.1194 25.324 25.1688 25.4176 25.265C25.5138 25.3587 25.5633 25.477 25.5633 25.6188V27.6579C25.5633 27.801 25.5138 27.9167 25.4176 28.0065C25.324 28.0975 25.2056 28.1417 25.0639 28.1417H19.5601V32.4126C19.5601 32.5413 19.5146 32.6545 19.4249 32.7507C19.3339 32.8482 19.2181 32.8964 19.0764 32.8964H16.4962Z"
                fill="black"
              />
              <path
                d="M28.4512 32.8964C28.3225 32.8964 28.2093 32.8482 28.1131 32.7507C28.0195 32.6545 27.9727 32.5413 27.9727 32.4126V19.9018C27.9727 19.76 28.0195 19.6443 28.1131 19.5532C28.2093 19.4635 28.3225 19.418 28.4512 19.418H31.2031C31.3449 19.418 31.4606 19.4635 31.5516 19.5532C31.6414 19.6443 31.6869 19.76 31.6869 19.9018V32.4126C31.6869 32.5413 31.6414 32.6545 31.5516 32.7507C31.4606 32.8482 31.3449 32.8964 31.2031 32.8964H28.4512Z"
                fill="black"
              />
              <path
                d="M34.7852 32.8964C34.6565 32.8964 34.5433 32.8482 34.4471 32.7507C34.3535 32.6545 34.3066 32.5413 34.3066 32.4126V19.9226C34.3066 19.7808 34.3496 19.6612 34.4367 19.5636C34.5264 19.4674 34.6422 19.418 34.7852 19.418H36.9597C37.2575 19.418 37.476 19.5415 37.6151 19.7873L42.1617 26.9661V19.9226C42.1617 19.7808 42.2046 19.6612 42.2917 19.5636C42.3815 19.4674 42.4972 19.418 42.6403 19.418H45.0852C45.2269 19.418 45.3427 19.4674 45.4337 19.5636C45.5235 19.6612 45.569 19.7808 45.569 19.9226V32.397C45.569 32.5361 45.5235 32.6545 45.4337 32.7507C45.3427 32.8482 45.2269 32.8964 45.0852 32.8964H42.9108C42.6155 32.8964 42.3971 32.7754 42.2553 32.5322L37.7296 25.6396V32.4126C37.7296 32.5556 37.6801 32.6714 37.5839 32.7611C37.4903 32.8521 37.3719 32.8964 37.2302 32.8964H34.7852Z"
                fill="black"
              />
              <path
                d="M47.4299 32.8964C47.3284 32.8964 47.2374 32.8547 47.1541 32.7715C47.0709 32.6883 47.0293 32.5946 47.0293 32.4906C47.0293 32.4152 47.0345 32.358 47.0449 32.3189L51.5342 19.9798C51.6617 19.6052 51.9192 19.418 52.3041 19.418H55.3057C55.6906 19.418 55.9468 19.6052 56.0756 19.9798L60.5597 32.3189C60.5727 32.358 60.5805 32.4152 60.5805 32.4906C60.5805 32.5946 60.5389 32.6883 60.4557 32.7715C60.3724 32.8547 60.2788 32.8964 60.1748 32.8964H57.6726C57.3631 32.8964 57.1446 32.7559 57.0171 32.475L56.3253 30.6075H51.2845L50.5875 32.475C50.4587 32.7559 50.2402 32.8964 49.932 32.8964H47.4299ZM52.0908 27.7152H55.519L53.8023 22.8097L52.0908 27.7152Z"
                fill="black"
              />
              <path
                d="M62.5196 32.8964C62.3909 32.8964 62.2777 32.8482 62.1815 32.7507C62.0878 32.6545 62.041 32.5413 62.041 32.4126V19.9226C62.041 19.7808 62.0839 19.6612 62.1711 19.5636C62.2608 19.4674 62.3765 19.418 62.5196 19.418H64.694C64.9919 19.418 65.2103 19.5415 65.3495 19.7873L69.896 26.9661V19.9226C69.896 19.7808 69.939 19.6612 70.0261 19.5636C70.1158 19.4674 70.2316 19.418 70.3746 19.418H72.8196C72.9613 19.418 73.0771 19.4674 73.1681 19.5636C73.2578 19.6612 73.3034 19.7808 73.3034 19.9226V32.397C73.3034 32.5361 73.2578 32.6545 73.1681 32.7507C73.0771 32.8482 72.9613 32.8964 72.8196 32.8964H70.6451C70.3499 32.8964 70.1314 32.7754 69.9897 32.5322L65.4639 25.6396V32.4126C65.4639 32.5556 65.4145 32.6714 65.3183 32.7611C65.2246 32.8521 65.1063 32.8964 64.9645 32.8964H62.5196Z"
                fill="black"
              />
              <path
                d="M81.5044 33.0899C79.6694 33.0899 78.2284 32.6477 77.1815 31.7634C76.1333 30.8764 75.5793 29.5785 75.5169 27.8723C75.5026 27.5264 75.4961 26.9606 75.4961 26.1764C75.4961 25.3831 75.5026 24.8057 75.5169 24.4442C75.5793 22.7769 76.1385 21.4894 77.1971 20.5843C78.2584 19.6791 79.6941 19.2266 81.5044 19.2266C82.6449 19.2266 83.6684 19.4138 84.5736 19.7884C85.4787 20.1603 86.194 20.6987 86.722 21.4062C87.2487 22.1111 87.5244 22.9447 87.5491 23.9084C87.5491 24.0228 87.5075 24.119 87.4243 24.1945C87.3411 24.2712 87.2422 24.3089 87.1278 24.3089H84.3915C84.2107 24.3089 84.0794 24.2751 83.9962 24.2049C83.9129 24.136 83.8401 24.0046 83.7777 23.8095C83.5969 23.1827 83.3225 22.7431 82.9558 22.4934C82.5916 22.2411 82.1078 22.1137 81.5044 22.1137C80.0543 22.1137 79.304 22.9239 79.2519 24.543C79.2376 24.8902 79.2311 25.4221 79.2311 26.14C79.2311 26.8579 79.2376 27.4041 79.2519 27.7787C79.304 29.3952 80.0543 30.2028 81.5044 30.2028C82.0935 30.2028 82.5773 30.0753 82.9558 29.8178C83.3368 29.5616 83.6112 29.1247 83.7777 28.5069C83.8258 28.3171 83.8947 28.187 83.9858 28.1168C84.0755 28.044 84.2107 28.0075 84.3915 28.0075H87.1278C87.2422 28.0075 87.3411 28.0466 87.4243 28.122C87.5075 28.1987 87.5491 28.2963 87.5491 28.4133C87.5244 29.3744 87.2487 30.208 86.722 30.9155C86.194 31.6203 85.4787 32.1587 84.5736 32.5333C83.6684 32.9039 82.6449 33.0899 81.5044 33.0899Z"
                fill="black"
              />
              <path
                d="M89.9903 32.8964C89.8616 32.8964 89.7484 32.8482 89.6522 32.7507C89.5585 32.6545 89.5117 32.5413 89.5117 32.4126V19.9226C89.5117 19.7808 89.5546 19.6612 89.6418 19.5636C89.7315 19.4674 89.8472 19.418 89.9903 19.418H99.0782C99.22 19.418 99.3383 19.4674 99.4319 19.5636C99.5282 19.6612 99.5776 19.7808 99.5776 19.9226V21.8265C99.5776 21.9696 99.5282 22.0853 99.4319 22.175C99.3383 22.2622 99.22 22.3051 99.0782 22.3051H92.9971V24.75H98.6568C98.7947 24.75 98.913 24.7994 99.0106 24.8957C99.1068 24.9932 99.1562 25.1129 99.1562 25.2546V27.0025C99.1562 27.1455 99.1068 27.2652 99.0106 27.3614C98.913 27.459 98.7947 27.5071 98.6568 27.5071H92.9971V30.0092H99.2343C99.3721 30.0092 99.4905 30.0587 99.588 30.1549C99.6842 30.2485 99.7337 30.3669 99.7337 30.5086V32.4126C99.7337 32.5556 99.6842 32.6714 99.588 32.7611C99.4905 32.8521 99.3721 32.8964 99.2343 32.8964H89.9903Z"
                fill="black"
              />
            </svg>
          </Link>

          <button
            className={isActive ? `${s.burger} ${s.show}` : s.burger}
            onClick={handleClick}
          >
            <span className={s.burgerItem}></span>
          </button>

          <nav className={isActive ? `${s.nav} ${s.show}` : s.nav}>
            <Link
              className={s.navLink}
              to="services"
              smooth={true}
              duration={500}
              offset={-150}
              onClick={handleNavLinkClick}
            >
              SERVICES
            </Link>

            <Link
              className={s.navLink}
              to="about"
              smooth={true}
              duration={500}
              offset={-150}
              onClick={handleNavLinkClick}
            >
              ABOUT US
            </Link>

            <Link
              className={s.navLink}
              to="contact"
              smooth={true}
              duration={500}
              offset={-150}
              onClick={handleNavLinkClick}
            >
              CONTACTS
            </Link>

            <Link
              className={s.navLink}
              to="faq"
              smooth={true}
              duration={500}
              offset={-150}
              onClick={handleNavLinkClick}
            >
              FAQ
            </Link>
          </nav>

          <div className={s.contactInfo}>
            <div className={s.wallet}>
              <a href="#">
                <BsCurrencyExchange className={s.icon} />
                Currency <br /> Exchange
              </a>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;
